<div class="btn-group" style="width: 100%">
    <button type="button"
            class="form-control form-select multiSelect"
            [ngClass]="classesBtn"
            (click)="toggleDropdown()"      
            style="white-space: nowrap; overflow-x: hidden; text-overflow: ellipsis;">
        {{ getButtonText() }}
    </button>
    <ul class="dropdown-menu"
        [ngStyle]="{ display: open ? 'block' : 'none' }"
        >
        <li *ngIf="showSelectAll" (click)="selectAll()">
            <span class="glyphicon glyphicon-ok"></span>
            {{ labels.selectAll || 'Select All' }}
        </li>
        <li *ngIf="showSelectAll || showUnselectAll" class="divider"></li>
        <li *ngIf="showUnselectAll" (click)="unselectAll()">
            <span class="glyphicon glyphicon-remove"></span>
            {{ labels.unselectAll || 'Unselect All' }}
        </li>
        <li *ngIf="showSelectAll || showUnselectAll" class="divider"></li>

        <ng-container *ngFor="let option of selectedOptions">
            <li role="presentation"
                class="active item-selected"
                [attr.title]="showTooltip ? getDisplay(option) : null"
                (click)="toggleItem(option); $event.stopPropagation()"
                style="overflow-x: hidden; text-overflow: ellipsis;">
                <i class="fa fa-times" aria-hidden="true" style="padding-right: 5px"></i>
                {{ getDisplay(option) }}
            </li>
        </ng-container>

        <li *ngIf="selectedOptions?.length > 0" class="divider"></li>

        <li *ngIf="showSearch">
            <input type="text"
                   class="form-control"
                   style="width: 100%;"
                   [(ngModel)]="searchFilter"
                   [placeholder]="labels.search || 'Search...'"
                   (ngModelChange)="updateSelectionLists()" />
        </li>

        <li *ngIf="showSearch" class="divider"></li>

        <ng-container *ngFor="let option of unselectedOptions | afFilter: search() | slice: 0:searchLimit">
            <li role="presentation"
                [ngClass]="{ 'disabled': selectionLimit && selectedOptions.length >= selectionLimit }"
                class="item-unselected"
                [attr.title]="showTooltip ? getDisplay(option) : null"
                (click)="toggleItem(option); $event.stopPropagation()"
                style="overflow-x: hidden; text-overflow: ellipsis;">
                {{ getDisplay(option) }}
            </li>
        </ng-container>

        <li class="divider" *ngIf="selectionLimit > 1"></li>

        <li role="presentation" *ngIf="selectionLimit > 1">
            <span>
                {{ selectedOptions.length || 0 }} / {{ selectionLimit }}
                {{ labels.itemsSelected || 'selected' }}
            </span>
        </li>
    </ul>
</div>
