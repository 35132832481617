import { Component, Directive, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
interface Option {
    code: string;
    title: string;
}

@Component({
    selector: 'af-multiselect',
    templateUrl: './multiselect.component.html',
    styleUrls: ['./multiselect.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiselectComponent),
            multi: true,
        }
    ],
})
export class MultiselectComponent implements ControlValueAccessor, OnInit {
    @Input() options: Option[] = [];
    @Input() displayProp: string = '';
    @Input() name: string = '';
    @Input() searchLimit: number = 50;
    @Input() selectionLimit: number = 0;
    @Input() showSelectAll: boolean = false;
    @Input() showUnselectAll: boolean = false;
    @Input() showSearch: boolean = false;
    @Input() searchFilter: string = '';
    @Input() disabled: boolean = false;
    @Input() labels: any = {
        "itemsSelected": "Označené",
        "selectAll": "Označiť všetko",
        "unselectAll": "Zrušiť všetko",
        "search": "Hľadať",
        "select": "Označiť"
    };
    @Input() classesBtn: string[] = [];
    @Input() showTooltip: boolean = false;
    @Input() placeholder: string = '';

    open: boolean = false;
    resolvedOptions: Option[] = [];
    @Input() selectedOptions: Option[] = [];
    @Output() selectedOptionsChange: EventEmitter<Option[]> = new EventEmitter<Option[]>();

    unselectedOptions: Option[] = [];
    onChangeCallback: (selectedOptions: Option[]) => void = () => { };
    onTouchedCallback: () => void = () => { };

    constructor(
        private el: ElementRef,
        private renderer: Renderer2
    ) { } 

    ngOnInit(): void { 
        if (typeof this.classesBtn === 'undefined') {
            this.classesBtn = ['btn-block', 'btn-default'];
        }

        if (typeof this.disabled !== 'undefined') {
            this.disabled = true;
        }
    }

    getRecursiveProperty(object: any, path: string): any {
        return path.split('.').reduce((obj, prop) => obj && obj[prop], object);
    }

    //getId(item: any): string {
    //    if (typeof item === 'string') {
    //        return item;
    //    } else if (typeof item === 'object') {
    //        if (this.name) {
    //            return this.getRecursiveProperty(item, this.name);
    //        } else {
    //            console.error('Multiselect: when using objects as model, a name value is mandatory.');
    //            return '';
    //        }
    //    } else {
    //        return item;
    //    }
    //}

    //getDisplay(item: any): string {
    //    if (typeof item === 'string') {
    //        return item;
    //    } else if (typeof item === 'object') {
    //        if (this.displayProp) {
    //            return this.getRecursiveProperty(item, this.displayProp);
    //        } else {
    //            console.error('Multiselect: when using objects as model, a displayProp value is mandatory.');
    //            return '';
    //        }
    //    } else {
    //        return item;
    //    }
    //}
    getId(item: Option): string {
        return item.code;
    }

    getDisplay(item: Option): string {
        return item.title;
    }

    isSelected(item: Option): boolean {
        if (!this.selectedOptions) {
            return false;
        }
        const itemId = this.getId(item);
        return this.selectedOptions.some(selectedElement => this.getId(selectedElement) === itemId);
    }
    
    updateSelectionLists(): void {
        this.resolvedOptions = [...this.options];
        if (!this.selectedOptions) {
            this.selectedOptions = [];
            this.unselectedOptions = [...this.resolvedOptions];
        } else {
            this.selectedOptions = this.resolvedOptions.filter(el => {
                const id = this.getId(el);
                return this.selectedOptions.some(selectedItem => this.getId(selectedItem) === id);
            });
            this.unselectedOptions = this.resolvedOptions.filter(el => !this.isSelected(el));
        }
    }

    search(): (item: Option) => boolean {
        let counter = 0;
        return (item: Option) => {
            if (counter > this.searchLimit) {
                return false;
            }
            const displayName = this.getDisplay(item);
            if (displayName) {
                const result = displayName.toLowerCase().includes(this.searchFilter.toLowerCase());
                if (result) {
                    counter++;
                }
                return result;
            }
            return false;
        };
    }

    toggleDropdown(): void {
        this.open = !this.open;
        this.resolvedOptions = [...this.options];
        this.updateSelectionLists();
    }

    @HostListener('document:click', ['$event'])
    closeDropdown(event: Event): void {
        if (!this.el.nativeElement.contains(event.target)) {
            this.open = false;
        }
    }

    selectAll(): void {
        this.selectedOptions = [...this.resolvedOptions];
        this.unselectedOptions = [];
        this.updateSelectionLists();
        this.updateSelectedOptions(this.selectedOptions);
    }

    unselectAll(): void {
        this.selectedOptions = [];
        this.unselectedOptions = [...this.resolvedOptions];
        this.updateSelectionLists();
        this.updateSelectedOptions(this.selectedOptions);
    }

    toggleItem(item: Option): void {
        const selectedIndex = this.selectedOptions.findIndex(selected => this.getId(selected) === this.getId(item));
        const currentlySelected = selectedIndex !== -1;
        if (currentlySelected) {
            this.unselectedOptions.push(this.selectedOptions[selectedIndex]);
            this.selectedOptions.splice(selectedIndex, 1);
        } else if (!currentlySelected && (this.selectionLimit === 0 || this.selectedOptions.length < this.selectionLimit)) {
            const unselectedIndex = this.unselectedOptions.findIndex(unselected => this.getId(unselected) === this.getId(item));
            this.unselectedOptions.splice(unselectedIndex, 1);
            this.selectedOptions.push(item);
        }
        this.updateSelectedOptions(this.selectedOptions);
    }

    getButtonText(): string {
        if (this.selectedOptions.length === 1) {
            return this.getDisplay(this.selectedOptions[0]);
        } else if (this.selectedOptions.length > 1) {
            return `${this.selectedOptions.length} ${this.labels.itemsSelected || 'selected'}`;
        } else {
            return this.labels.select || this.placeholder || 'Select';
        }
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(this.el.nativeElement, 'open');
    }

    writeValue(value: Option[]): void {
        this.selectedOptions = value || [];
        this.updateSelectionLists();
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    updateSelectedOptions(options: Option[]): void {
        this.selectedOptions = options;
        this.selectedOptionsChange.emit(options);
        this.onChangeCallback(this.selectedOptions);
    }
}
