
import { filter } from "rxjs/operators";
import { Component, OnInit, OnDestroy, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { Store } from "@ngrx/store";

import { State } from "../../../store/reducers";
import { MenuService } from "../../../core/services/menu.service";
import { MenuItem } from "../../../models/menu-item";

export class Tab {
    title: string;
    originalUrl: string;
    url: string;
    isActive: boolean;
    isVisible: boolean;
    code: string;
}

@Component({
    selector: "af-tabs",
    styles: [
        `
        .nav-tabs {
            margin-bottom: 25px;
        }
    `
    ],
    templateUrl: "./tabs.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabComponent implements OnInit, OnDestroy {
    @Input() queryParamsHandling: any;
    @Output() updated = new EventEmitter<any>();
    @Input() parentId: number;
    tabs = new Array<Tab>();
    sub: Subscription;

    constructor(private menuService: MenuService, private store: Store<State>, private changeDetectorRef: ChangeDetectorRef) { }

    ngOnInit() {
        this.sub = this.store
            .select(x => x.menu.menu).pipe(
                filter(loaded => !!loaded))
            .subscribe(() => {
                this.updateTabs();
                this.changeDetectorRef.markForCheck();
            });
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    update() {
        this.changeDetectorRef.markForCheck();
    }

    resetTabs() {
        this.tabs.splice(0, this.tabs.length);
        let item = this.menuService.getActiveItem();
        let params = this.menuService.getCurrentParams();
        if (item && item.parentId) {
            let children = this.findNearestTabs(this.parentId ?? item.parentId);
            if (!children) {
                return;
            }

            for (let i = 0; i < children.length; i++) {
                let child = children[i];
                let url = this.menuService.getUrl(child, params);
                let isActive = item.id === child.id || item.parentId === child.id;
                this.tabs.push({
                    title: child.title,
                    url,
                    originalUrl: child.url,
                    isActive,
                    isVisible: true,
                    code: child.code
                });
            }
        }
    }

    updateTabs() {
        this.resetTabs();
        this.updated.emit();
    }

    findNearestTabs(id: number) {
        let item = this.menuService.getItem(id);
        let tabs = null;

        if (item && item.children) {
            tabs = item.children.filter(x => x.type === "Tab");
        }

        if (!tabs || tabs.length !== 0) {
            return tabs;
        } else {
            return this.findNearestTabs(item.parentId);
        }
    }

    setTab(item: Tab) {
        for (let tab of this.tabs) {
            tab.isActive = false;
        }

        item.isActive = true;
    }
}
