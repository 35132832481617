<af-page-filter [loading]="isLoading" [params]="queryParams" [hideButtons]="true">
    <div class="form-group" *ngIf="!isProjectTaskDetail">
        <label>{{ 'hollen.clientapp.src.app.operation.report.report-project-task.component.branch' | translate }}</label>
        <select class="form-control form-select" [ngModel]="queryParams.branchId" (ngModelChange)="branchChanged($event)">
            <option *ngFor="let branch of branches" [ngValue]="branch.branchId">{{branch.title}}</option>
        </select>
    </div>
    <div class="form-group" *ngIf="!isProjectTaskDetail">
        <label>{{ 'hollen.clientapp.src.app.operation.report.report-project-task.component.project task' | translate }}</label>
        <div class="input-group">
                <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
            <ng-select [items]="projectTaskFilterItems" #projectTaskAutocompleteSelect style="min-width: 400px;"
                       [virtualScroll]="true" (scrollToEnd)="projectTaskAutocompleteFetchMore(projectTaskAutocompleteSelect.searchTerm)" [loading]="isProjectTaskAutocompleteLoading"
                       [typeahead]="projectTaskTypeahead" (clear)="onProjectTaskSelect(null)"
                       [ngModel]="selectedProjectTask" (ngModelChange)="onProjectTaskSelect($event)">
                <ng-template ng-label-tmp let-item="item">
                    {{ item.fullProjectTaskNumber }} - {{ item.title }}
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <span [ngOptionHighlight]="search">{{ item.fullProjectTaskNumber }}</span> - {{ item.title }}
                </ng-template>
            </ng-select>
        </div>
    </div>
    <div class="form-group" *ngIf="isProjectTaskDetail && projectTask">
        <label>{{ 'hollen.clientapp.src.app.operation.report.report-project-task.component.project task' | translate }}</label>
        <b>{{ projectTask.projectTaskNumber }} - {{ projectTask.title }}</b>
    </div>
    <div class="filter-group">
        <div class="form-group">
            <label>{{ 'hollen.clientapp.src.app.operation.report.report-project-task.component.from' | translate }}</label>
            <af-datepicker [ngModel]="queryParams.from" (ngModelChange)="queryParams.from = $event; updateParams();"></af-datepicker>
        </div>
        <div class="form-group">
            <label>{{ 'hollen.clientapp.src.app.operation.report.report-project-task.component.to' | translate }}</label>
            <af-datepicker [ngModel]="queryParams.to" (ngModelChange)="queryParams.to = $event; updateParams();"></af-datepicker>
        </div>
    </div>
    <div class="form-group">
        <label>{{ 'hollen.clientapp.src.app.operation.report.report-project-task.component.language' | translate }}</label>
        <select class="form-control form-select" [ngModel]="queryParams.languageId" (ngModelChange)="queryParams.languageId = $event; onLanguageChange();">
            <option *ngFor="let language of languages" [ngValue]="language.key">{{language.value}}</option>
        </select>
    </div>
    <ng-container *ngIf="isProjectTaskDetail && reportData">
        <div class="form-group">
            <label>{{ 'hollen.clientapp.src.app.operation.report.report-project-task.component.first and last work report in month' | translate }}</label>
            <b>{{ reportData.firstWorkReportDate | afDate: 'L' }} – {{ reportData.lastWorkReportDate | afDate: 'L' }}</b>
        </div>
        <div class="form-group">
            <label>{{ 'hollen.clientapp.src.app.operation.report.report-project-task.component.hours _ pcs on monthly product rate' | translate }}</label>
            <b *ngIf="reportData.hasMonthlyNonZeroProductRate">{{ 'hollen.clientapp.src.app.operation.report.report-project-task.component.yes' | translate }}</b>
            <b *ngIf="!reportData.hasMonthlyNonZeroProductRate">{{ 'hollen.clientapp.src.app.operation.report.report-project-task.component.no' | translate }}</b>
        </div>
    </ng-container>
</af-page-filter>

<div class="row btn-select-box">
    <div class="col-sm-12">
        <button [ngClass]="{'btn-selected': selectedReport === 0}" class="btn btn-default mb-1" (click)="cancelFilter()">{{ 'hollen.clientapp.src.app.operation.report.report-project-task.component.all' | translate }}</button>
        <ng-container *ngFor="let item of reports">
            <button *ngIf="isAvailable(item.id)" [ngClass]="{'btn-selected': selectedReport === item.id}" (click)="selectReport(item.id)" class="btn btn-default mb-1">{{ item.title }}</button>
        </ng-container>
        <ng-container *ngIf="!['SP', 'SN', 'SV', 'CC', 'CS', 'CH'].includes(reportData.accountCenterCode)">
            <button *ngIf="isAvailable(-11)" [ngClass]="{'btn-selected': selectedReport === -11}" (click)="selectReport(-11)" class="btn btn-default mb-1">{{ 'hollen.clientapp.src.app.operation.report.report-project-task.component.working procedure' | translate }}</button>
        </ng-container>
        <button *ngIf="isAvailable(-9)" [ngClass]="{'btn-selected': selectedReport === -9}" (click)="selectReport(-9)" class="btn btn-default mb-1">{{ 'hollen.clientapp.src.app.operation.report.report-project-task.component.project task overview' | translate }}</button>
        <button *ngIf="isAvailable(-10)" [ngClass]="{'btn-selected': selectedReport === -10}" (click)="selectReport(-10)" class="btn btn-default mb-1">{{ 'hollen.clientapp.src.app.operation.report.report-project-task.component.month report' | translate }}</button>
    </div>
</div>

<br />

<div class="row" [class.disabled-all]="!queryParams.projectTaskId">
    <ng-container *ngFor="let item of reports">
        <div class="col-md-4 mb-md-2" *ngIf="isVisible(item.id)">
            <op-report-project-task-detail [params]="queryParams" [item]="item"></op-report-project-task-detail>
        </div>
    </ng-container>
    <ng-container *ngIf="queryParams.projectTaskId && (selectedReport === 0 || selectedReport === -11 || selectedReport === -12)">
        <div class="col-md-8">
            <div class="row" opProjectTaskDocuments [selected]="selectedReport" [projectTaskId]="queryParams.projectTaskId" [languageId]="queryParams.languageId"></div>
        </div>
    </ng-container>
    <op-warenfilter-report *ngIf="isVisible(-3)" [params]="queryParams" class="col-md-4 mb-md-2"></op-warenfilter-report>
    <op-project-task-overview-report *ngIf="isVisible(-9)" [params]="queryParams" [branch]="selectedBranch" class="col-md-4 mb-md-2"></op-project-task-overview-report>
    <op-month-report *ngIf="isVisible(-10)" [params]="queryParams" class="col-md-4 mb-md-2"></op-month-report>
</div>
